import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import DynamicComponent from '../components/DynamicComponent'

// GQL fragments
import '../fragments/HeroContentQuery'
import '../fragments/TextImageContentQuery'
import '../fragments/OneColContentQuery'
import '../fragments/TwoColsContentQuery'
import '../fragments/ThreeColsContentQuery'
import '../fragments/TextImageSquaresContentQuery'
import '../fragments/QuoteContentQuery'
import '../fragments/StaticImageVideoContentQuery'
import '../fragments/TemplateElementContentQuery'
import '../fragments/DivideContentQuery'
import '../fragments/FormsContentQuery'
import '../fragments/FaqContentQuery'

export default function ProductListPage({ pageContext, data }) {
  const pageInfo = data.craftProdukterKategoriEntry

  const ogImageOverride = pageInfo.ogImage[0]?.ogimage
  const ogImageGlobal = data.craftHeaderGlobalSet.ogImage[0]?.ogimage

  const ogImage = ogImageOverride || ogImageGlobal

  return (
    <Layout
      pageTitle={pageInfo.title}
      pageDesc={pageInfo.metaDescription}
      ogImage={ogImage}
    >
      <DynamicComponent
        contentTypes={pageInfo.dynamicContent}
        products={pageContext.products}
        defaultSpecs={
          data.allCraftVinduerCategory?.nodes[0]?.specs ||
          data.allCraftDoreCategory?.nodes[0]?.specs ||
          data.allCraftGlasdoreCategory?.nodes[0]?.specs ||
          []
        }
        relatedAssets={data.allCraftDocumentsAsset.nodes}
        relatedOptions={data.allCraftOptionsCategory.nodes}
      />
    </Layout>
  )
}

export const query = graphql`
  fragment SPECS on Craft_specs_specs_BlockType {
    heading
    table {
      data
      name
    }
  }
  query dynamicContentQuery($slug: String!, $id: ID!) {
    craftHeaderGlobalSet {
      ogImage {
        ogimage
      }
    }
    craftProdukterKategoriEntry(slug: { eq: $slug }) {
      title
      metaDescription
      ogImage {
        ogimage
      }
      dynamicContent {
        __typename
        ...HeroContent
        ...TextImageContent
        ...TextImageSquaresContent
        ...OneColContent
        ...TwoColsContent
        ...ThreeColsContent
        ...QuoteContent
        ...StaticImageVideoContent
        ...TemplateElementContent
        ...DivideContent
        ...FormsContent
        ...FaqContent
      }
    }
    allCraftVinduerCategory(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        specs {
          ...SPECS
        }
      }
    }
    allCraftDoreCategory(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        specs {
          ...SPECS
        }
      }
    }
    allCraftGlasdoreCategory(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        specs {
          ...SPECS
        }
      }
    }
    allCraftDocumentsAsset(
      filter: { entries: { elemMatch: { remoteId: { eq: $id } } } }
    ) {
      nodes {
        filename
        url
        extension
        title
      }
    }
    allCraftOptionsCategory(
      sort: { fields: title, order: ASC }
      filter: {
        entries: { elemMatch: { remoteId: { eq: $id } } }
        enabled: { eq: true }
      }
    ) {
      nodes {
        title
        parent: remoteParent {
          title
        }
        productImage {
          title
          ... on Craft_productImages_Asset {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        colorPicker
      }
    }
  }
`
